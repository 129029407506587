<template>
  <footer class="min-h-40 flex flex-col items-center bg-main-dark p-2">
    <ul class="flex flex-col text-center text-base font-light text-white">
      <li>Login</li>
      <li>API-Dokumentation</li>
      <li>Impressum</li>
      <li>Datenschutz</li>
    </ul>
    <p class="p-2 text-sm font-light text-white">
      Copyright by Ulisses Spiele {{ currentYear }}
    </p>
  </footer>
</template>
<script setup lang="ts">
const currentYear = ref(new Date().getFullYear());
</script>
